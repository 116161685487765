<template>
    <aside class="sidebar" :class="{ 'sidebar-hidden': !isSidebarOpen }">
        <div class="sidebar-header">
            <img src="@/assets/logo.png" alt="Logo" class="logo rounded bg-white p-2" v-if="isSidebarOpen">
            <h3 v-if="isSidebarOpen" class="mt-2">TSC App</h3>
            <button class="btn btn-close-sidebar" v-if="isMobile" @click="$emit('toggleSidebar')">
                <i class="bi bi-x"></i>
            </button>
        </div>
        <nav class="sidebar-nav">
            <ul>
                <li><router-link to="/dashboard" class="nav-link"><i class="bi bi-house-door"></i> <span
                            v-if="isSidebarOpen">Inicio</span></router-link></li>
                <li><router-link to="/assistance" class="nav-link"><i class="bi bi-life-preserver"></i> <span
                            v-if="isSidebarOpen">Asistencias</span></router-link></li>
                <li><router-link to="/clients" class="nav-link"><i class="bi bi-person-badge"></i> <span
                            v-if="isSidebarOpen">Clientes</span></router-link></li>
                <li><router-link to="/technicians" class="nav-link"><i class="bi bi-tools"></i> <span
                            v-if="isSidebarOpen">Técnicos</span></router-link></li>
                <li><router-link to="/users" class="nav-link"><i class="bi bi-people"></i> <span
                            v-if="isSidebarOpen">Usuarios</span></router-link></li>
                <li><router-link to="/website" class="nav-link"><i class="bi bi-globe"></i> <span
                            v-if="isSidebarOpen">Página Web</span></router-link></li>
            </ul>
        </nav>
    </aside>
</template>

<script>
export default {
    name: 'AppSidebar',
    props: {
        isSidebarOpen: {
            type: Boolean,
            required: true
        },
        isMobile: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        toggleSidebar() {
            this.$emit('toggleSidebar');
        }
    }
};
</script>

<style scoped>
.sidebar {
    width: 240px;
    background-color: #a50034;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    transform: translateX(0);
}

.sidebar-hidden {
    transform: translateX(-100%);
}

.sidebar-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
}

.logo {
    width: 100px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
}

.btn-close-sidebar {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

.sidebar-nav ul {
    list-style: none;
    padding: 0;
}

.nav-link {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;
    transition: background-color 0.3s;
}

.nav-link:hover {
    background-color: #8c0102;
}

.nav-link i {
    margin-right: 10px;
    font-size: 1.5rem;
}
</style>
