// src/services/axios.js

import axios from 'axios';
import router from '@/router';
import store from '@/store';

const API_BASE_URL = process.env.VUE_APP_API_URL || 'http://localhost:8000'; // Cambia la base URL según sea necesario

// Instancia para rutas API (sin '/api' en la baseURL)
const apiInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
});

// Instancia para rutas web (login, logout, CSRF)
const webInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
});

// Función para obtener la cookie CSRF
const getCsrfCookie = async () => {
    try {
        const response = await webInstance.get('/sanctum/csrf-cookie');
        console.log('CSRF Cookie response:', response);
    } catch (error) {
        console.error('Error obteniendo CSRF cookie:', error);
    }
};

// Interceptor para manejar errores de autenticación
const authInterceptor = (error) => {
    if (error.response && [401, 419].includes(error.response.status)) {
        store.commit('CLEAR_USER');
        router.push({ name: 'Login' });
    }
    return Promise.reject(error);
};

// Aplicar el interceptor a ambas instancias
apiInstance.interceptors.response.use(
    (response) => response,
    authInterceptor
);

webInstance.interceptors.response.use(
    (response) => response,
    authInterceptor
);

// Exportar instancias y funciones
export { apiInstance as api, webInstance as web, getCsrfCookie };
