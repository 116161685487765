<template>
    <header class="main-header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <button class="btn btn-hamburger" @click="$emit('toggleSidebar')"><i class="bi bi-list"></i></button>
            <h2 class="mb-0 ms-3">{{ title }}</h2>
        </div>
        <div class="d-flex align-items-center">
            <a href="https://tscenter.com.co" class="btn view-site-button me-3">
                <i class="bi bi-eye"></i>
            </a>
            <button class="btn logout-button" @click="handleLogout">
                <i class="bi bi-box-arrow-right"></i>
            </button>
        </div>
    </header>
</template>

<script>
import { web, getCsrfCookie } from '@/services/axios';

export default {
    name: 'AppHeader',
    props: {
        title: {
            type: String,
            required: true
        }
    },
    methods: {
        async handleLogout() {
            try {
                // Obtener el token CSRF antes de hacer el logout
                await getCsrfCookie();

                // Hacer la solicitud al backend para cerrar sesión
                await web.post('/logout');

                // Llamar a la acción de logout del store
                this.$store.dispatch('logout');

                // Redirigir al login después de cerrar sesión
                this.$router.push({ name: 'Login' });
            } catch (error) {
                console.error('Error al cerrar sesión:', error);
                this.$router.push({ name: 'Login' }); // Redirigir al login en caso de error
            }
        }
    }
};
</script>

<style scoped>
.main-header {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.btn-hamburger {
    background: none;
    border: 1px solid transparent;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, border 0.3s ease;
}

.btn-hamburger:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.view-site-button {
    background: none;
    border: 1px solid transparent;
    color: #A50034;
    font-size: 1.5rem;
    cursor: pointer;
    transition: border 0.3s ease, background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.view-site-button:hover {
    border: 1px solid #A50034;
    background-color: rgba(165, 0, 52, 0.1);
    color: #A50034;
}

.logout-button {
    background: none;
    border: 1px solid transparent;
    color: #A50034;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.3s ease, background-color 0.3s ease;
}

.logout-button:hover {
    border: 1px solid #A50034;
    background-color: rgba(165, 0, 52, 0.1);
}

.logout-button i {
    font-size: 1.5rem;
}
</style>