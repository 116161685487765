import { createStore } from 'vuex';
import { api } from '@/services/axios'; // Usar la instancia api para autenticación
import router from '@/router';

export default createStore({
    state: {
        user: JSON.parse(localStorage.getItem('user')) || null, // Cargar el usuario desde localStorage
        authToken: localStorage.getItem('auth_token') || null, // Cargar el token desde localStorage
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user)); // Guardar el usuario en localStorage
        },
        SET_TOKEN(state, token) {
            state.authToken = token;
            localStorage.setItem('auth_token', token); // Guardar el token en localStorage
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Establecer el token para futuras solicitudes
        },
        CLEAR_USER(state) {
            state.user = null;
            state.authToken = null;
            localStorage.removeItem('user'); // Eliminar el usuario de localStorage
            localStorage.removeItem('auth_token'); // Eliminar el token de localStorage
            delete api.defaults.headers.common['Authorization']; // Eliminar el token de las solicitudes
        },
    },
    actions: {
        async login({ commit }, credentials) {
            try {
                // Enviar credenciales de login usando la instancia 'api'
                const response = await api.post('/login', credentials);

                const token = response.data.token; // Obtener el token del response

                // Guardar el token en Vuex y localStorage
                commit('SET_TOKEN', token);

                // Guardar el usuario en Vuex (si la respuesta contiene información del usuario)
                commit('SET_USER', response.data.user);
            } catch (error) {
                console.error('Error de login:', error);
                throw error;
            }
        },
        async fetchUser({ commit }) {
            if (this.state.authToken) {
                try {
                    const { data } = await api.get('/user'); // Obtén el usuario autenticado
                    commit('SET_USER', data);
                } catch (error) {
                    commit('CLEAR_USER');
                    console.error('Error obteniendo el usuario:', error);
                    router.push({ name: 'Login' }); // Redirigir al login si no se puede obtener el usuario
                }
            }
        },
        logout({ commit }) {
            commit('CLEAR_USER');
            router.push({ name: 'Login' });
        },
    },
    getters: {
        isAuthenticated: (state) => !!state.authToken,
    },
});
