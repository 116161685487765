<template>
    <div class="dashboard-container" :class="{ 'sidebar-open': isSidebarOpen }" @click="closeSidebarOnClickOutside">
        <AppSidebar :isSidebarOpen="isSidebarOpen" :isMobile="isMobile" @toggleSidebar="toggleSidebar" />
        <div class="main-content">
            <AppHeader :title="'Dashboard'" @toggleSidebar="toggleSidebar" />
            <section class="content p-4">
                <div class="row mb-4">
                    <div class="col-lg-3 col-md-6 mb-4">
                        <div class="card card-stat card-hover">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="icon-container">
                                        <i class="bi bi-person-circle"></i>
                                    </div>
                                    <div class="text-container ms-3">
                                        <h3>245</h3>
                                        <p>Usuarios registrados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-4">
                        <div class="card card-stat card-hover">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="icon-container">
                                        <i class="bi bi-tools"></i>
                                    </div>
                                    <div class="text-container ms-3">
                                        <h3>68</h3>
                                        <p>Técnicos activos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-4">
                        <div class="card card-stat card-hover">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="icon-container">
                                        <i class="bi bi-life-preserver"></i>
                                    </div>
                                    <div class="text-container ms-3">
                                        <h3>35</h3>
                                        <p>Asistencias técnicas en progreso</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-4">
                        <div class="card card-stat card-hover">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="icon-container">
                                        <i class="bi bi-card-list"></i>
                                    </div>
                                    <div class="text-container ms-3">
                                        <h3>120</h3>
                                        <p>Servicios en catálogo</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 mb-4">
                        <div class="card card-chart">
                            <div class="card-body">
                                <h4>Estadísticas de Asistencias Técnicas</h4>
                                <canvas id="assistanceChart"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-4">
                        <div class="card card-notifications">
                            <div class="card-body">
                                <h4>Notificaciones Recientes</h4>
                                <ul class="notifications-list">
                                    <li>
                                        <i class="bi bi-bell-fill"></i> Nueva asistencia técnica asignada a Juan Pérez
                                    </li>
                                    <li>
                                        <i class="bi bi-bell-fill"></i> Cliente Carlos García solicitó mantenimiento
                                    </li>
                                    <li>
                                        <i class="bi bi-bell-fill"></i> Técnico María López completó una instalación
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';
import { api } from '@/services/axios'; // Importa la instancia correcta de axios (api)
import Chart from 'chart.js/auto';

export default {
    name: 'DashboardPage',
    components: {
        AppSidebar,
        AppHeader
    },
    data() {
        return {
            isSidebarOpen: window.innerWidth > 992,
            isMobile: window.innerWidth <= 992
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        closeSidebarOnClickOutside(event) {
            if (this.isMobile && this.isSidebarOpen && !event.target.closest('.sidebar') && !event.target.closest('.btn-hamburger')) {
                this.isSidebarOpen = false;
            }
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 992;
            if (window.innerWidth > 992) {
                this.isSidebarOpen = true;
            } else {
                this.isSidebarOpen = false;
            }
        },
        async fetchData() {
            try {
                // Aquí puedes agregar lógica para usar la respuesta del servidor si es necesario
                await api.get('/dashboard'); // Realiza una solicitud al endpoint de tu dashboard si lo tienes
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        },
        renderAssistanceChart() {
            const ctx = document.getElementById('assistanceChart').getContext('2d');
            new Chart(ctx, {
                type: 'line',
                data: {
                    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
                    datasets: [{
                        label: 'Asistencias Técnicas',
                        data: [12, 19, 3, 5, 2, 3],
                        borderColor: '#a50034',
                        fill: false
                    }]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true
                        }
                    }
                }
            });
        }
    },
    async mounted() {
        try {
            // Intentar obtener los datos del usuario y verificar autenticación
            await this.$store.dispatch('fetchUser');
            if (!this.$store.getters.isAuthenticated) {
                this.$router.push({ name: 'Login' }); // Redirigir al login si no está autenticado
            } else {
                window.addEventListener('resize', this.handleResize);
                this.handleResize();
                this.fetchData(); // Llamar a la función para obtener los datos del dashboard
                this.renderAssistanceChart(); // Renderizar el gráfico
            }
        } catch (error) {
            this.$router.push({ name: 'Login' }); // Redirigir al login si hay un error
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
};
</script>

<style scoped>
.dashboard-container {
    display: flex;
    height: 100vh;
    background-color: #f8f9fa;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.main-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-left: 240px;
    transition: margin-left 0.3s ease-in-out;
}

.sidebar-hidden + .main-content {
    margin-left: 0;
}

.card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    background-color: #fff;
}

.card-hover:hover {
    transform: translateY(-5px);
}

.card-stat {
    border: none;
}

.card-chart {
    border: none;
    padding: 20px;
}

.card-notifications {
    border: none;
    padding: 20px;
}

.icon-container {
    font-size: 2.5rem;
    color: #a50034;
}

.text-container h3 {
    margin: 0;
    font-weight: bold;
    color: #333;
}

.text-container p {
    margin: 0;
    color: #666;
}

.notifications-list {
    list-style: none;
    padding: 0;
}

.notifications-list li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 0.9rem;
    color: #333;
}

.notifications-list li i {
    color: #a50034;
    margin-right: 10px;
}

/* Responsive Styles */
@media (max-width: 992px) {
    .main-content {
        margin-left: 0;
    }
}
</style>
