<template>
    <div class="assistance-page">
        <AppSidebar :isSidebarOpen="isSidebarOpen" :isMobile="isMobile" @toggleSidebar="toggleSidebar" />
        <div class="main-content">
            <AppHeader :title="'Asistencias Técnicas'" @toggleSidebar="toggleSidebar" />
            <section class="content p-4">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Cliente</th>
                                <th>Servicio</th>
                                <th>Técnico</th>
                                <th>Estado</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-label="#">1</td>
                                <td data-label="Cliente">Carlos García</td>
                                <td data-label="Servicio">Reparación de Lavadora</td>
                                <td data-label="Técnico">Juan Pérez</td>
                                <td data-label="Estado"><span class="badge badge-warning">En progreso</span></td>
                                <td data-label="Fecha">2024-10-13 10:30 AM</td>
                            </tr>
                            <tr>
                                <td data-label="#">2</td>
                                <td data-label="Cliente">María López</td>
                                <td data-label="Servicio">Instalación de Secadora</td>
                                <td data-label="Técnico">Ana Fernández</td>
                                <td data-label="Estado"><span class="badge badge-success">Completado</span></td>
                                <td data-label="Fecha">2024-10-13 09:00 AM</td>
                            </tr>
                            <tr>
                                <td data-label="#">3</td>
                                <td data-label="Cliente">Pedro Gómez</td>
                                <td data-label="Servicio">Mantenimiento de Refrigerador</td>
                                <td data-label="Técnico">Carlos Martínez</td>
                                <td data-label="Estado"><span class="badge badge-danger">Pendiente</span></td>
                                <td data-label="Fecha">2024-10-13 11:00 AM</td>
                            </tr>
                            <tr>
                                <td data-label="#">4</td>
                                <td data-label="Cliente">Lucía Rivera</td>
                                <td data-label="Servicio">Reparación de Estufa</td>
                                <td data-label="Técnico">Sofía Ramírez</td>
                                <td data-label="Estado"><span class="badge badge-warning">En progreso</span></td>
                                <td data-label="Fecha">2024-10-13 12:00 PM</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';
import { api } from '@/services/axios'; // Importa la instancia correcta de axios (api)

export default {
    name: 'AssistancePage',
    components: {
        AppSidebar,
        AppHeader
    },
    data() {
        return {
            isSidebarOpen: window.innerWidth > 992,
            isMobile: window.innerWidth <= 992,
            assistances: [] // Arreglo para guardar las asistencias
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 992;
            if (window.innerWidth > 992) {
                this.isSidebarOpen = true;
            } else {
                this.isSidebarOpen = false;
            }
        },
        async fetchAssistances() {
            try {
                const response = await api.get('/assistances'); // Ajusta la ruta según tu API
                this.assistances = response.data;
            } catch (error) {
                console.error('Error fetching assistances:', error);
            }
        }
    },
    async mounted() {
        try {
            // Intentar obtener los datos del usuario y verificar autenticación
            await this.$store.dispatch('fetchUser');
            if (!this.$store.getters.isAuthenticated) {
                this.$router.push({ name: 'Login' }); // Redirigir al login si no está autenticado
            } else {
                window.addEventListener('resize', this.handleResize);
                this.handleResize();
                this.fetchAssistances(); // Llamar a la función para obtener las asistencias
            }
        } catch (error) {
            this.$router.push({ name: 'Login' }); // Redirigir al login si hay un error
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
};
</script>

<style scoped>
.assistance-page {
    display: flex;
    height: 100vh;
    background-color: #f8f9fa;
    position: relative;
}

.main-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-left: 240px;
    transition: margin-left 0.3s ease-in-out;
}

.sidebar-hidden + .main-content {
    margin-left: 0;
}

.table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table-responsive {
    overflow-x: auto;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.badge-warning {
    color: #856404;
    background-color: #fff3cd;
}

.badge-success {
    color: #155724;
    background-color: #d4edda;
}

.badge-danger {
    color: #721c24;
    background-color: #f8d7da;
}

@media (max-width: 992px) {
    .main-content {
        margin-left: 0;
    }

    .table thead {
        display: none;
    }

    .table, .table tbody, .table tr, .table td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 15px;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }
}
</style>
