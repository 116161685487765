<template>
    <div class="vh-100 d-flex align-items-center justify-content-center position-relative">
        <video autoplay muted loop class="position-absolute w-100 h-100" style="object-fit: cover; z-index: -1;">
            <source src="@/assets/videos/LoginBack.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="card p-4 shadow-lg" style="max-width: 400px; width: 100%;">
            <div class="text-center mb-4">
                <img src="@/assets/logo.png" alt="TSCenter Logo" class="img-fluid mb-3" style="height: 70px;">
                <h2>Acceso Administrativo</h2>
            </div>
            <form @submit.prevent="handleLogin">
                <div class="mb-3">
                    <label for="email" class="form-label">Correo Electrónico</label>
                    <input type="email" id="email" v-model="credentials.email"
                        class="form-control border border-secondary" :class="{ 'is-invalid': errors.email }">
                    <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
                </div>
                <div class="mb-3 position-relative">
                    <label for="password" class="form-label">Contraseña</label>
                    <div class="input-group">
                        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="credentials.password"
                            class="form-control border border-secondary" :class="{ 'is-invalid': errors.password }">
                        <button type="button" class="btn btn-outline-secondary border border-secondary"
                            @click="togglePasswordVisibility">
                            <i class="bi" :class="showPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
                        </button>
                    </div>
                    <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
                </div>
                <button type="submit" class="btn btn-primary w-100 mb-3">Ingresar</button>
            </form>
            <div class="text-center">
                <router-link to="/forgot-password" class="text-decoration-none">Olvidé mi contraseña</router-link>
            </div>
            <div class="text-center mt-3">
                <a href="https://tscenter.com.co" class="text-decoration-none fw-bold"><i class="bi bi-arrow-left"></i>
                    Atrás</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
    name: 'LoginPage',
    data() {
        return {
            credentials: {
                email: '',
                password: '',
            },
            showPassword: false,
            errors: {},
            retryCount: 0, // Contador de reintentos
            maxRetries: 1, // Número máximo de reintentos
        };
    },
    computed: {
        ...mapGetters(['isAuthenticated']),
    },
    async mounted() {
        // Limpiar el almacenamiento local al cargar la página de login
        localStorage.clear();
        sessionStorage.clear();

        // Limpiar el estado del usuario en Vuex
        this.$store.commit('CLEAR_USER');

        // Redirigir al dashboard si ya está autenticado
        if (this.isAuthenticated) {
            this.$router.push({ name: 'Dashboard' });
        }
    },
    methods: {
        async handleLogin() {
            this.errors = {};

            try {
                // Limpiar todos los datos almacenados antes de realizar el login
                localStorage.clear();
                sessionStorage.clear();
                this.$store.commit('CLEAR_USER');

                // Llamar a la acción de login en el store
                await this.$store.dispatch('login', this.credentials);

                // Mostrar mensaje de éxito
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Inicio de sesión exitoso',
                    showConfirmButton: false,
                    timer: 3000,
                });

                // Redirigir al dashboard sin recargar la página
                this.$router.push({ name: 'Dashboard' });
            } catch (error) {
                // Manejar errores
                if (error.response) {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 401) {
                        // Reintentar automáticamente si no se ha alcanzado el máximo de reintentos
                        if (this.retryCount < this.maxRetries) {
                            this.retryCount++;
                            await this.handleLogin();
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error de autenticación',
                                text: 'Credenciales incorrectas. Por favor, intenta de nuevo.',
                            });
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error inesperado',
                            text: 'Ocurrió un error. Por favor, intenta de nuevo más tarde.',
                        });
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error inesperado',
                        text: 'No se pudo conectar con el servidor. Por favor, intenta de nuevo más tarde.',
                    });
                }
            }
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        }
    },
};
</script>

<style scoped>
.card {
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.9);
}

.btn-primary {
    background-color: #a50034;
    border: none;
}

.btn-primary:hover {
    background-color: #8c0102;
}

.btn-outline-secondary {
    border: 1px solid #ccc;
    color: #a50034;
}

.btn-outline-secondary:hover {
    color: #8c0102;
}

.text-decoration-none {
    color: #a50034;
}

.text-decoration-none:hover {
    text-decoration: underline;
}

.form-control {
    border: 1px solid #ccc;
}

.text-danger {
    font-size: 0.875rem;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
</style>