<template>
    <div class="users-page">
        <AppSidebar :isSidebarOpen="isSidebarOpen" :isMobile="isMobile" @toggleSidebar="toggleSidebar" />
        <div class="main-content">
            <AppHeader :title="'Usuarios'" @toggleSidebar="toggleSidebar" />
            <section class="content p-4">
                <button class="btn btn-success btn-add-user mb-3" @click="navigateToCreateUser">Agregar Usuario</button>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Teléfono</th>
                                <th>Email</th>
                                <th>Rol</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, index) in paginatedUsers" :key="user.id">
                                <td data-label="#">{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                <td data-label="Nombre">{{ user.name }}</td>
                                <td data-label="Teléfono">{{ user.phone }}</td>
                                <td data-label="Email" class="email-cell">{{ user.email }}</td>
                                <td data-label="Rol">{{ user.role == 0 ? 'Administrador' : 'Comercial' }}</td>
                                <td data-label="Acciones">
                                    <button class="btn btn-sm btn-outline-primary" @click="navigateToEditUser(user.id)">Editar</button>
                                    <button class="btn btn-sm btn-outline-danger" @click="confirmDeleteUser(user.id)">Eliminar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pagination mt-3">
                        <button class="btn btn-outline-secondary" @click="prevPage" :disabled="currentPage === 1">Anterior</button>
                        <span class="mx-2">Página {{ currentPage }} de {{ totalPages }}</span>
                        <button class="btn btn-outline-secondary" @click="nextPage" :disabled="currentPage === totalPages">Siguiente</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';
import { api } from '@/services/axios';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
    name: 'UsersPage',
    components: {
        AppSidebar,
        AppHeader
    },
    data() {
        return {
            isSidebarOpen: window.innerWidth > 992,
            isMobile: window.innerWidth <= 992,
            users: [],
            currentPage: 1,
            itemsPerPage: 10
        };
    },
    computed: {
        paginatedUsers() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.users.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.users.length / this.itemsPerPage);
        }
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 992;
            if (window.innerWidth > 992) {
                this.isSidebarOpen = true;
            } else {
                this.isSidebarOpen = false;
            }
        },
        async fetchUsers() {
            try {
                const response = await api.get('/users'); // Asegúrate de usar 'api'
                this.users = response.data;
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        },
        navigateToCreateUser() {
            this.$router.push({ name: 'CreateUser' });
        },
        navigateToEditUser(userId) {
            this.$router.push({ name: 'EditUser', params: { id: userId } });
        },
        confirmDeleteUser(userId) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: 'No podrás revertir esta acción',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.deleteUser(userId);
                }
            });
        },
        async deleteUser(userId) {
            try {
                await api.delete(`/users/${userId}`); // Usa 'api' para la solicitud de eliminación
                this.users = this.users.filter(user => user.id !== userId);
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Usuario eliminado correctamente',
                    showConfirmButton: false,
                    timer: 3000
                });
            } catch (error) {
                console.error('Error deleting user:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrió un error al eliminar el usuario. Por favor, intenta de nuevo.'
                });
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.fetchUsers(); // Cargar los usuarios cuando se monta el componente
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
};
</script>

<style scoped>
.users-page {
    display: flex;
    height: 100vh;
    background-color: #f8f9fa;
    position: relative;
}

.main-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-left: 240px;
    transition: margin-left 0.3s ease-in-out;
}

.sidebar-hidden+.main-content {
    margin-left: 0;
}

.table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.email-cell {
    max-width: 150px;
    /* Ajusta el valor según lo necesario */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btn {
    margin-right: 5px;
    padding: 5px 10px;
    font-size: 0.8rem;
}

.btn-outline-primary {
    color: #007bff;
    border: 1px solid #007bff;
}

.btn-outline-primary:hover {
    background-color: #007bff;
    color: #fff;
}

.btn-outline-danger {
    color: #dc3545;
    border: 1px solid #dc3545;
}

.btn-outline-danger:hover {
    background-color: #dc3545;
    color: #fff;
}

.btn-add-user {
    background-color: #28a745;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.btn-add-user:hover {
    background-color: #218838;
}

.table-responsive {
    overflow-x: auto;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 992px) {
    .main-content {
        margin-left: 0;
    }

    .table thead {
        display: none;
    }

    .table,
    .table tbody,
    .table tr,
    .table td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 15px;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }

    .email-cell {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>