<template>
    <div class="create-user-page">
        <AppSidebar :isSidebarOpen="isSidebarOpen" :isMobile="isMobile" @toggleSidebar="toggleSidebar" />
        <div class="main-content">
            <AppHeader :title="'Crear Usuario'" @toggleSidebar="toggleSidebar" />
            <section class="content p-4">
                <div class="d-flex justify-content-end mb-3">
                    <button @click="$router.push({ name: 'Users' })" type="button" class="btn btn-outline-primary">
                        <i class="bi bi-arrow-left me-2"></i> Atrás
                    </button>
                </div>
                <form @submit.prevent="createUser" class="form-container row">
                    <div class="form-group col-md-6 mb-4">
                        <label for="name" class="form-label">Nombre</label>
                        <input type="text" id="name" v-model.trim="user.name" class="form-control"
                            @input="validateField('name')" />
                        <small v-if="errors.name" class="text-danger d-block mt-1">{{ errors.name }}</small>
                    </div>
                    <div class="form-group col-md-6 mb-4">
                        <label for="phone" class="form-label">Teléfono</label>
                        <input type="text" id="phone" v-model.trim="user.phone" class="form-control"
                            @input="validateField('phone')" @keypress="isNumber($event)" />
                        <small v-if="errors.phone" class="text-danger d-block mt-1">{{ errors.phone }}</small>
                    </div>
                    <div class="form-group col-md-6 mb-4">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" id="email" v-model.trim="user.email" class="form-control"
                            @input="validateField('email')" />
                        <small v-if="errors.email" class="text-danger d-block mt-1">{{ errors.email }}</small>
                    </div>
                    <div class="form-group col-md-6 mb-4">
                        <label for="role" class="form-label">Rol</label>
                        <select id="role" v-model.number="user.role" class="form-control"
                            @change="validateField('role')">
                            <option value="0">Administrador</option>
                            <option value="1">Comercial</option>
                        </select>
                        <small v-if="errors.role" class="text-danger d-block mt-1">{{ errors.role }}</small>
                    </div>
                    <div class="form-group col-md-6 mb-4">
                        <label for="password" class="form-label">Contraseña</label>
                        <div class="input-group">
                            <input :type="showPassword ? 'text' : 'password'" id="password" v-model.trim="user.password"
                                class="form-control" @input="validateField('password')" />
                            <span class="input-group-text" @click="togglePasswordVisibility">
                                <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                            </span>
                        </div>
                        <small v-if="errors.password" class="text-danger d-block mt-1">{{ errors.password }}</small>
                    </div>
                    <div class="form-group col-md-6 mb-4">
                        <label for="confirmPassword" class="form-label">Confirmar Contraseña</label>
                        <div class="input-group">
                            <input :type="showConfirmPassword ? 'text' : 'password'" id="confirmPassword"
                                v-model.trim="user.confirmPassword" class="form-control"
                                @input="validateField('confirmPassword')" />
                            <span class="input-group-text" @click="toggleConfirmPasswordVisibility">
                                <i :class="showConfirmPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                            </span>
                        </div>
                        <small v-if="errors.confirmPassword" class="text-danger d-block mt-1">{{ errors.confirmPassword
                            }}</small>
                    </div>
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary w-100 btn-create-user">Crear Usuario</button>
                    </div>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';
import { api, getCsrfCookie } from '@/services/axios';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export default {
    name: 'CreateUser',
    components: {
        AppSidebar,
        AppHeader
    },
    data() {
        return {
            isSidebarOpen: window.innerWidth > 992,
            isMobile: window.innerWidth <= 992,
            user: {
                name: '',
                phone: '',
                email: '',
                role: 0,
                password: '',
                confirmPassword: ''
            },
            errors: {
                name: '',
                phone: '',
                email: '',
                role: '',
                password: '',
                confirmPassword: ''
            },
            showPassword: false,
            showConfirmPassword: false
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 992;
            this.isSidebarOpen = window.innerWidth > 992;
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        toggleConfirmPasswordVisibility() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        validateField(field) {
            switch (field) {
                case 'name':
                    this.errors.name = this.user.name ? '' : 'El nombre es obligatorio.';
                    break;
                case 'phone':
                    this.errors.phone = this.user.phone ? '' : 'El teléfono es obligatorio.';
                    break;
                case 'email':
                    if (!this.user.email) {
                        this.errors.email = 'El email es obligatorio.';
                    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.user.email)) {
                        this.errors.email = 'El email no es válido.';
                    } else {
                        this.errors.email = '';
                    }
                    break;
                case 'role':
                    this.errors.role = this.user.role !== '' ? '' : 'El rol es obligatorio.';
                    break;
                case 'password':
                    this.errors.password = this.user.password ? '' : 'La contraseña es obligatoria.';
                    break;
                case 'confirmPassword':
                    this.errors.confirmPassword = this.user.password === this.user.confirmPassword ? '' : 'Las contraseñas no coinciden.';
                    break;
                default:
                    break;
            }
        },
        async createUser() {
            // Validar todos los campos antes de enviar
            Object.keys(this.user).forEach(field => this.validateField(field));

            if (Object.values(this.errors).some(error => error !== '')) {
                return;
            }

            try {
                // Obtener el token CSRF
                await getCsrfCookie();

                // Enviar la solicitud para crear el usuario
                await api.post('/users', this.user, {
                    withCredentials: true
                });

                // Mostrar mensaje de éxito con SweetAlert Toast
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    title: 'Usuario creado exitosamente',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.$router.push({ name: 'Users' });
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Redirigir al login si la sesión ha expirado
                    this.$router.push({ name: 'Login' });
                } else if (error.response && error.response.data.errors) {
                    for (const key in error.response.data.errors) {
                        this.errors[key] = error.response.data.errors[key][0];
                    }
                } else {
                    console.error('Error creando usuario:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Ocurrió un error al crear el usuario. Por favor, intenta de nuevo.'
                    });
                }
            }
        },
        isNumber(evt) {
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode < 48 || charCode > 57) {
                evt.preventDefault();
            }
        }
    },
    async mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        // Verificar si el usuario está autenticado, si no, redirigir al login
        try {
            await this.$store.dispatch('fetchUser');
            if (!this.$store.getters.isAuthenticated) {
                this.$router.push({ name: 'Login' });
            }
        } catch (error) {
            this.$router.push({ name: 'Login' });
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
};
</script>

<style scoped>
.create-user-page {
    display: flex;
    height: 100vh;
    background-color: #f8f9fa;
    position: relative;
}

.main-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-left: 240px;
    transition: margin-left 0.3s ease-in-out;
}

.sidebar-hidden+.main-content {
    margin-left: 0;
}

.form-container {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    background: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
}

.form-container:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-label {
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.form-control {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: border-color 0.3s ease-in-out;
    font-size: 1rem;
}

.form-control:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

.btn-create-user {
    background-color: #a50034;
    border: none;
    padding: 14px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
}

.btn-create-user:hover {
    background-color: #8c0102;
}

.btn-outline-primary {
    color: #a50034;
    border-color: #a50034;
    transition: background-color 0.3s, color 0.3s;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 8px;
}

.btn-outline-primary:hover {
    background-color: #a50034;
    color: #fff;
}

.text-danger {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 5px;
    display: block;
}

.input-group {
    position: relative;
    display: flex;
}

.input-group-text {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 10px 10px 0;
    background: #f8f9fa;
}

@media (max-width: 992px) {
    .main-content {
        margin-left: 0;
    }

    .form-container {
        width: 100%;
        padding: 20px;
    }
}
</style>