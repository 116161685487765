<template>
    <div class="website-page">
        <AppSidebar :isSidebarOpen="isSidebarOpen" :isMobile="isMobile" @toggleSidebar="toggleSidebar" />
        <div class="main-content">
            <AppHeader :title="'CMS Página Web'" @toggleSidebar="toggleSidebar" />
            <section class="content p-4">
                <h1>Gestión de Página Web</h1>
                <p>Aquí puedes modificar las secciones de la página web:</p>
                <div class="cards-container">
                    <div class="card" @click="$router.push('/website/home')">
                        <i class="bi bi-house-door"></i>
                        <h3>Inicio</h3>
                        <p>Gestiona la sección principal de tu página web.</p>
                    </div>
                    <div class="card" @click="$router.push('/website/about')">
                        <i class="bi bi-info-circle"></i>
                        <h3>Nosotros</h3>
                        <p>Modifica la información sobre tu empresa.</p>
                    </div>
                    <div class="card" @click="$router.push('/website/services')">
                        <i class="bi bi-briefcase"></i>
                        <h3>Servicios</h3>
                        <p>Gestiona los servicios que ofreces a tus clientes.</p>
                    </div>
                    <div class="card" @click="$router.push('/website/catalog')">
                        <i class="bi bi-card-list"></i>
                        <h3>Catálogo</h3>
                        <p>Administra el catálogo de productos y servicios.</p>
                    </div>
                    <div class="card" @click="$router.push('/website/contact')">
                        <i class="bi bi-envelope"></i>
                        <h3>Contáctanos</h3>
                        <p>Modifica la información de contacto y formulario.</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';

export default {
    name: 'WebsitePage',
    components: {
        AppSidebar,
        AppHeader
    },
    data() {
        return {
            isSidebarOpen: window.innerWidth > 992,
            isMobile: window.innerWidth <= 992
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 992;
            if (window.innerWidth > 992) {
                this.isSidebarOpen = true;
            } else {
                this.isSidebarOpen = false;
            }
        }
    },
    async mounted() {
        try {
            // Verificar autenticación
            await this.$store.dispatch('fetchUser');
            if (!this.$store.getters.isAuthenticated) {
                this.$router.push({ name: 'Login' }); // Redirigir al login si no está autenticado
            } else {
                window.addEventListener('resize', this.handleResize);
                this.handleResize();
            }
        } catch (error) {
            this.$router.push({ name: 'Login' }); // Redirigir al login si hay un error
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
};
</script>

<style scoped>
.website-page {
    display: flex;
    height: 100vh;
    background-color: #f8f9fa;
    position: relative;
}

.main-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-left: 240px;
    transition: margin-left 0.3s ease-in-out;
}

.sidebar-hidden + .main-content {
    margin-left: 0;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card i {
    font-size: 2rem;
    color: #a50034;
    margin-bottom: 10px;
}

.card h3 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.card p {
    margin: 10px 0 0;
    color: #666;
}

@media (max-width: 992px) {
    .main-content {
        margin-left: 0;
    }
}
</style>
