<template>
    <div class="technicals-page">
        <AppSidebar :isSidebarOpen="isSidebarOpen" :isMobile="isMobile" @toggleSidebar="toggleSidebar" />
        <div class="main-content">
            <AppHeader :title="'Técnicos'" @toggleSidebar="toggleSidebar" />
            <section class="content p-4">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Teléfono</th>
                                <th>Email</th>
                                <th>Especialidad</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(technical, index) in technicals" :key="technical.id">
                                <td data-label="#">{{ index + 1 }}</td>
                                <td data-label="Nombre">{{ technical.name }}</td>
                                <td data-label="Teléfono">{{ technical.phone }}</td>
                                <td data-label="Email" class="email-cell">{{ technical.email }}</td>
                                <td data-label="Especialidad">{{ technical.specialty }}</td>
                                <td data-label="Acciones">
                                    <button class="btn btn-sm btn-outline-primary">Editar</button>
                                    <button class="btn btn-sm btn-outline-danger">Eliminar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';
import { api } from '@/services/axios'; // Importa la instancia correcta de axios

export default {
    name: 'TechnicalsPage',
    components: {
        AppSidebar,
        AppHeader
    },
    data() {
        return {
            isSidebarOpen: window.innerWidth > 992,
            isMobile: window.innerWidth <= 992,
            // Simulación de técnicos
            technicals: [
                { id: 1, name: 'Juan Pérez', phone: '+57 300 123 4567', email: 'juan.perez@example.com', specialty: 'Reparación de Lavadoras' },
                { id: 2, name: 'Ana Fernández', phone: '+57 310 987 6543', email: 'ana.fernandez@example.com', specialty: 'Instalación de Secadoras' },
                { id: 3, name: 'Carlos Martínez', phone: '+57 320 555 6789', email: 'carlos.martinez@example.com', specialty: 'Mantenimiento de Refrigeradores' }
            ]
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        handleResize() {
            this.isMobile = window.innerWidth <= 992;
            if (window.innerWidth > 992) {
                this.isSidebarOpen = true;
            } else {
                this.isSidebarOpen = false;
            }
        },
        async fetchTechnicals() {
            try {
                const response = await api.get('/technicals'); // Ajusta la ruta según tu API
                this.technicals = response.data;
            } catch (error) {
                console.error('Error fetching technicals:', error);
            }
        }
    },
    async mounted() {
        try {
            // Intentar obtener los datos del usuario y verificar autenticación
            await this.$store.dispatch('fetchUser');
            if (!this.$store.getters.isAuthenticated) {
                this.$router.push({ name: 'Login' }); // Redirigir al login si no está autenticado
            } else {
                window.addEventListener('resize', this.handleResize);
                this.handleResize();
                this.fetchTechnicals(); // Llamar a la función para obtener los técnicos
            }
        } catch (error) {
            this.$router.push({ name: 'Login' }); // Redirigir al login si hay un error
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
};
</script>

<style scoped>
.technicals-page {
    display: flex;
    height: 100vh;
    background-color: #f8f9fa;
    position: relative;
}

.main-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-left: 240px;
    transition: margin-left 0.3s ease-in-out;
}

.sidebar-hidden + .main-content {
    margin-left: 0;
}

.table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.email-cell {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}

.btn {
    margin-right: 5px;
    padding: 5px 10px;
    font-size: 0.8rem;
}

.btn-outline-primary {
    color: #007bff;
    border: 1px solid #007bff;
}

.btn-outline-primary:hover {
    background-color: #007bff;
    color: #fff;
}

.btn-outline-danger {
    color: #dc3545;
    border: 1px solid #dc3545;
}

.btn-outline-danger:hover {
    background-color: #dc3545;
    color: #fff;
}

.table-responsive {
    overflow-x: auto;
}

@media (max-width: 992px) {
    .main-content {
        margin-left: 0;
    }

    .table thead {
        display: none;
    }

    .table,
    .table tbody,
    .table tr,
    .table td {
        display: block;
        width: 100%;
    }

    .table tr {
        margin-bottom: 15px;
    }

    .table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }
}
</style>
