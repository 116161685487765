import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; // Importa el store de Vuex
import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import Assistance from '@/views/Assistance.vue';
import Clients from '@/views/Clients.vue';
import Technicians from '@/views/Technicians.vue';
import Users from '@/views/users/Users.vue';
import CreateUser from '@/views/users/CreateUser.vue';
import EditUser from '@/views/users/EditUser.vue';
import Website from '@/views/Website.vue';

const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true } },
  { path: '/assistance', name: 'Assistance', component: Assistance, meta: { requiresAuth: true } },
  { path: '/clients', name: 'Clients', component: Clients, meta: { requiresAuth: true } },
  { path: '/technicians', name: 'Technicians', component: Technicians, meta: { requiresAuth: true } },
  { path: '/users', name: 'Users', component: Users, meta: { requiresAuth: true } },
  { path: '/users/create', name: 'CreateUser', component: CreateUser, meta: { requiresAuth: true } },
  { path: '/users/:id/edit', name: 'EditUser', component: EditUser, meta: { requiresAuth: true } },
  { path: '/website', name: 'Website', component: Website, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Guardia de navegación para proteger rutas que requieren autenticación
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth) {
    // Verificar si el usuario está autenticado en el store
    if (!store.getters.isAuthenticated) {
      try {
        // Intentar obtener el usuario autenticado desde el backend
        await store.dispatch('fetchUser');
        if (store.getters.isAuthenticated) {
          // El usuario está autenticado, permitir acceso
          next();
        } else {
          // No está autenticado, redirigir al login
          next({ name: 'Login' });
        }
      } catch (error) {
        // Error al obtener el usuario, redirigir al login
        next({ name: 'Login' });
      }
    } else {
      // El usuario ya está autenticado, permitir acceso
      next();
    }
  } else {
    // La ruta no requiere autenticación
    if (to.name === 'Login' && store.getters.isAuthenticated) {
      // Si el usuario está autenticado y quiere ir al login, redirigir al dashboard
      next({ name: 'Dashboard' });
    } else {
      // Permitir acceso
      next();
    }
  }
});

export default router;
